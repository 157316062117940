/* global googletag, gptadslots */
import { dfpManager } from '../../../base/js/dfp/dfp-manager';


if (window.googletag && window.gptadslots) {
  googletag.cmd.push(() => {
    const mapping = googletag.sizeMapping()
      .addSize([1220, 0], [728, 90])
      .addSize([1024, 0], [])
      .addSize([768, 0], [728, 90])
      .addSize([0, 0], [320, 50])
      .build();

    gptadslots[12] = googletag
      .defineSlot('/55877742/FireRescue1',
        [[300, 250]],
        'div-gpt-ad-546180090590731748-12')
      .addService(googletag.pubads());

    gptadslots[11] = googletag
      .defineSlot('/55877742/FireRescue1',
        [[728, 90]],
        'div-gpt-ad-546180090590731748-11')
      .setTargeting('Pos', ['LB2'])
      .defineSizeMapping(mapping)
      .addService(googletag.pubads());

    dfpManager.setTargets(googletag);
    dfpManager.enable();
  });
}
